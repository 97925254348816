import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { User, Permission, UserService, UserInfo } from 'src/app/services/user.service'

@Component({
    selector: '[app-users-list-item]',
    templateUrl: './users-list-item.component.html',
    styleUrls: ['./users-list-item.component.scss'],
    standalone: false
})
export class UsersListItemComponent implements OnInit {
  @Input() userInfo!: UserInfo

  constructor(public dialog: MatDialog, public userService: UserService, public router: Router ) {}

  ngOnInit(): void {
  }

  
  get userTrueEmail():string | undefined{
    return this.userService.getTrueEmailFromUserInfo(this.userInfo)
}


  goToUserPage(id:number):void{
    this.router.navigate(['/admin/users',id])
  }
}
