import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';

@Component({
    selector: 'app-sous-groupes-create-page',
    templateUrl: './sous-groupes-create-page.component.html',
    styleUrls: ['./sous-groupes-create-page.component.scss'],
    standalone: false
})
export class SousGroupesCreatePageComponent implements OnInit {

  entreprisesCommanditairesWithoutPaginationSub: Subscription | undefined
  entreprises: Array<EntrepriseCommanditaire> | undefined


  constructor(
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService

  ) {}
  
  
  ngOnInit(): void {
    this.loadEntreprisesWithoutPagination()
  }
  

  loadEntreprisesWithoutPagination(){ 
    this.entreprisesCommanditairesWithoutPaginationSub?.unsubscribe 
    this.entreprisesCommanditairesWithoutPaginationSub = this.entrepriseCommanditaireService.getAllWithoutPagination().subscribe({
      next: (response: Array<EntrepriseCommanditaire>) => {
        this.entreprises = response

      },
      error: (res) => {
        alert("Failed to load entreprises" + res)},
    })
  }

  

   
  ngOnDestroy() {
    this.entreprisesCommanditairesWithoutPaginationSub?.unsubscribe()
  }

}
