import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import {
  EntreprisesPrestataires, EntreprisesPrestatairesService, formattedTypes
} from 'src/app/services/entreprises-prestataires.service'
import { PageItem } from 'src/app/services/utils'
import { UserInfo, UserService } from 'src/app/services/user.service'


@Component({
    selector: 'app-entreprises-prestataires-add-user-form',
    templateUrl: './entreprises-prestataires-add-user-form.component.html',
    styleUrls: ['./entreprises-prestataires-add-user-form.component.scss'],
    standalone: false
})
export class EntreprisesPrestatairesAddUserFormComponent implements OnChanges, OnInit {
  
  @Input() entreprisePrestataire!: EntreprisesPrestataires
  @Output() onSaved: EventEmitter<EntreprisesPrestataires> = new EventEmitter<EntreprisesPrestataires>()


  pending: boolean = false

  types_list = formattedTypes

  default_id: number = 0

  addUserForm = new FormGroup({
    entreprise_id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
  })

  constructor(protected entreprisesPrestatairesService: EntreprisesPrestatairesService,
              protected userService: UserService) {
  }

  usersSub: Subscription | undefined
  usersPage: PageItem<UserInfo> | undefined

  ngOnInit(): void {
    this.load()
  }

  load(): void {
    this.usersSub?.unsubscribe()
    this.usersSub = this.entreprisesPrestatairesService.getAllUsersWithNoEntreprise().subscribe({
      next: (x) => {
        this.usersPage = x
      },
    })
  }

  ngOnChanges(): void {
    this.addUserForm.get('entreprise_id')?.setValue(this.entreprisePrestataire.id)
  }

  getUserTrueEmail(userInfo : UserInfo):string | undefined{
    return this.userService.getTrueEmailFromUserInfo(userInfo)
}


  onSubmit(): void {
    if (this.addUserForm.invalid) {
      alert('Formulaire non valide')
      return
    }

    this.pending = true

    let data = this.addUserForm.value
    if (data.entreprise_id && data.id) {
      this.entreprisesPrestatairesService.addUser(data.entreprise_id, data.id).subscribe({
        next: (entreprisesPrestataires: EntreprisesPrestataires) => {
          this.pending = false
          this.onSaved.emit(entreprisesPrestataires)
        },
        error: (res) => {
          this.pending = false
          alert("Cannot update entreprise" + res)},
      })
    }


  }

  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
  }
}
