import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MissionService } from '../services/mission.service';

@Injectable({
  providedIn: 'root'
})
export class MissionGuard  {
  missionAccessSub: Subscription | undefined

  constructor(private missionService: MissionService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.missionService.canAccessMission(Number(route.paramMap.get('id')))
    }
  
}
