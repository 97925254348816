import { Component, Input, OnInit } from '@angular/core'
import { MissionStatut , FormattedMissionStatut} from 'src/app/services/mission.service'
@Component({
    selector: 'app-project-status-badge',
    templateUrl: './project-status-badge.component.html',
    styleUrls: ['./project-status-badge.component.scss'],
    standalone: false
})
export class ProjectStatusBadgeComponent implements OnInit {
  @Input() status!: string

  missionStatut = MissionStatut
  formattedMissionStatus = FormattedMissionStatut

  constructor() {}

  ngOnInit(): void {}
}
