import { Component } from '@angular/core';

@Component({
    selector: 'app-projects-list-admin',
    templateUrl: './projects-list-admin.component.html',
    styleUrls: ['./projects-list-admin.component.scss'],
    standalone: false
})
export class ProjectsListAdminComponent {

}
