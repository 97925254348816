import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MissionModel, ProjectMissionComponent } from '../project-mission/project-mission.component';

@Component({
    selector: 'app-project-headline',
    templateUrl: './project-headline.component.html',
    styleUrls: ['./project-headline.component.scss'],
    standalone: false
})
export class ProjectHeadlineComponent implements OnInit {
  showDetails: boolean = false
  @Output() showDetailsEvent = new EventEmitter<any>();

  constructor(
    private projectMissionComponent: ProjectMissionComponent
  ) {}

  ngOnInit(): void {
  }

  public ShowDetails() {
    this.showDetails = !this.showDetails
    this.showDetailsEvent.emit(this.showDetails)
    this.projectMissionComponent.ShowDetailsMission(this.showDetails)
  }
}
