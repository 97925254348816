import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
// import { SelectItem } from 'src/app/services/entity-base.service'
import { UserInfo } from 'src/app/services/user.service'

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    standalone: false
})
export class UsersListComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  @Input() users!: UserInfo[]
  @Output() onFilterApplied: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>()

  ngOnInit(): void {}


  

}
