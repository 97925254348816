import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-danger-message',
    templateUrl: './danger-message.component.html',
    styleUrls: ['./danger-message.component.scss'],
    standalone: false
})
export class DangerMessageComponent implements OnInit {

  @Input() title!: string 
  @Input() message_content!: string 
  
  ngOnInit(): void {}

  

}
