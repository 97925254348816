import { Component, Input, OnInit } from '@angular/core';
import { MissionDocument, MissionDocumentService } from 'src/app/services/mission-document.service';
@Component({
    selector: 'app-mission-documents-list',
    templateUrl: './mission-documents-list.component.html',
    styleUrls: ['./mission-documents-list.component.scss'],
    standalone: false
})
export class MissionDocumentsListComponent implements OnInit {

  constructor(
    protected missionDocumentService : MissionDocumentService,


  ) {}

  @Input() documents!: MissionDocument[]
  @Input() is_editable!: boolean

  ngOnInit(): void {

  }


  goToDeleteDocument(documentId:number):void{
    this.missionDocumentService.deleteDocumentWithConfirm(documentId)
    
  }

  goToDownloadDocument(documentId:number):void{
    this.missionDocumentService.downloadDocument(documentId)
    
  }


}