import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
    selector: 'app-sous-groupes-detail-page',
    templateUrl: './sous-groupes-detail-page.component.html',
    styleUrls: ['./sous-groupes-detail-page.component.scss'],
    standalone: false
})
export class SousGroupesDetailPageComponent  implements OnInit {
  constructor(
    private route: ActivatedRoute, 
    private titleService : TitleService, 
    protected subgroupService: SousGroupeCommanditaireService,
    protected router: Router
  ) {}


  
  subgroup!: SousGroupeCommanditaire | undefined 
  subgroupSub: Subscription | undefined 



  ngOnInit(): void {
    const obs = this.route.paramMap.pipe(
        map(params => params.get('id')!),
        mergeMap(id =>this.subgroupService.getId(+id))
             )

        this.subgroupSub?.unsubscribe()

        this.subgroupSub = obs.subscribe({
          next: (response: SousGroupeCommanditaire) => {
            this.subgroup = response , 
            this.titleService.setTitle(response.name)
          },
          error: (res) => {
            if (res.status == 404){
              this.router.navigate(['/not-found']) 
            }
           // alert("Failed to load subgroup" + res)
          },
        })

       }


       
  deleteSubgroup(): void {
    if (this.subgroup) {
      this.subgroupService.deleteSubgroupWithConfirmAndRedirectToList(this.subgroup)
    }
  }
  
  updateSubgroup(): void {
    if (this.subgroup) {
      this.subgroupService.goToAdminSubgroupUpdatePage(this.subgroup.id)
      
    }
  }


  subgroupUsers(): void {
    if (this.subgroup) {
      this.subgroupService.goToAdminSubgroupUsersPage(this.subgroup.id)
      
    }
  }






  ngOnDestroy(): void {
    this.subgroupSub?.unsubscribe()
  }

}