import { Component, Input, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { UserInfo, UserEntrepriseInfo, UserService } from 'src/app/services/user.service'

@Component({
    selector: 'app-user-current-bar-card',
    templateUrl: './user-current-bar-card.component.html',
    styleUrls: ['./user-current-bar-card.component.scss'],
    standalone: false
})
export class UserCurrentBarCardComponent implements OnInit {


  @Input() userEntrepriseInfo!: UserEntrepriseInfo
  @Input() loggedUser!: UserInfo


  constructor(protected userService: UserService) {}

  ngOnInit(): void {
  }

}
