import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { TitleService } from 'src/app/services/title.service';
import { PageItem } from 'src/app/services/utils';

@Component({
    selector: 'app-sous-groupes-list-page',
    templateUrl: './sous-groupes-list-page.component.html',
    styleUrls: ['./sous-groupes-list-page.component.scss'],
    standalone: false
})
export class SousGroupesListPageComponent implements OnInit {

  subgroupesSub: Subscription | undefined
  paginatedSubgroupes: PageItem<SousGroupeCommanditaire> | undefined


  constructor(
    protected subgroupesService: SousGroupeCommanditaireService , 
    private titleService : TitleService
  ) {}

  has_filter:boolean = false

  
  ngOnInit(): void {
    this.load()
    this.titleService.setSousGroupesTitle()
  }


  load(): void {
    this.subgroupesSub?.unsubscribe()
    this.subgroupesSub = this.subgroupesService.getAll().subscribe({
      next: (response: PageItem<SousGroupeCommanditaire>) => {
        this.paginatedSubgroupes = response 

      },
      error: (res) => {
        alert("Failed to load subgroupes" + res)},
    })
  }

  switchPage(url: string) {
    this.subgroupesSub?.unsubscribe()
    this.subgroupesSub = this.subgroupesService.getPage(url).subscribe({
      next: (response: PageItem<SousGroupeCommanditaire>) =>
        (this.paginatedSubgroupes = response),
      error: (_) =>
        alert(
          "Error while retrieving subgroups"
        ),
    })
  }

  goToSubgroup(id: number): void {
     this.subgroupesService.goToAdminSubgroupDetailPage(id)
  }

  goToSubgroupCreate(): void {
    this.subgroupesService.goToAdminESubgroupCreatePage()

  }

  ngOnDestroy() {
    this.subgroupesSub?.unsubscribe()
  }

}
