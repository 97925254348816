import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EntrepriseCommanditaire,
  EntrepriseCommanditaireService,
  EntrepriseCommanditaireType,
  EntrepriseUpdateData
} from 'src/app/services/entreprisecommanditaire.service'

@Component({
    selector: 'app-entreprises-commanditaires-create',
    templateUrl: './entreprises-commanditaires-create.component.html',
    styleUrls: ['./entreprises-commanditaires-create.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesCreateComponent  implements OnInit {

  constructor(
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService
  ) {}

  ngOnInit(): void {

  }

  pending: boolean = false
  activities =  EntrepriseCommanditaireType

  createEntrepriseForm = new FormGroup({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    type: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
  })


  onSubmit(): void {
    if (this.createEntrepriseForm.invalid) {
      alert('Form is not valid')
      return
    }

    let entreprise_data = this.createEntrepriseForm.value
    this.entrepriseCommanditaireService.createEntrepriseAndRedirectToList(entreprise_data)
  }
}

