import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PageItem } from 'src/app/services/utils'

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: false
})
export class PaginationComponent implements OnInit {
  @Input() classes!: string
  @Input() pagination!: PageItem<any>
  @Output() onSwitchPage: EventEmitter<string> = new EventEmitter<string>()

  constructor() {}

  ngOnInit(): void {}
}
