<div class="fixed inset-y-0 flex w-64 flex-col">
    <div class="flex min-h-0 flex-1 flex-col bg-gray-800 text-white">
      <div class="flex flex-1 flex-col overflow-y-auto">
        <div class="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4">
          <img
            routerLink="/"
            src="assets/deloitte_white.svg"
            class="h-7 w-auto cursor-pointer"
            alt="Deloitte"
          />
        </div>

        <nav class="mt-5 flex-1 space-y-1 px-2">
          <div class="flex h-full flex-col justify-between">
            <div class="space-y-1">
              <a
                routerLink="/"
                class="group flex items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('/', true)"
              >
                <svg
                  class="mr-3 h-6 w-6 flex-shrink-0"
                  [ngClass]="iconClasses('/', true)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>

                Home
              </a>

              <a
              routerLink="/reports"
              class="group flex items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
              [ngClass]="navElementClasses('reports', true)"
            >
              <svg
                class="mr-3 h-6 w-6 flex-shrink-0"
                [ngClass]="iconClasses('/', true)"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"

                  />
              </svg>
              Reports
            </a>

              <a  *ngIf="userEntrepriseInfo?.user_type == entrepriseType.PRESTATAIRE"
                routerLink="/create-mission"
                class="group flex items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('create')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3 h-6 w-6 flex-shrink-0"
                  [ngClass]="iconClasses('create')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                New mission
              </a>
            </div>
            <div class="mb-5" *ngIf="showAdminSubMenu">
              <button
                class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('admin')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3 h-6 w-6 flex-shrink-0"
                  [ngClass]="iconClasses('admin')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Administration
              </button>

              <div class="mt-1 space-y-1 pl-5">
                  <button
                  routerLink="/admin/users"
                  class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                  [ngClass]="navElementClasses('admin/users')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 h-6 w-6 flex-shrink-0"
                    [ngClass]="iconClasses('admin/users')"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  Users
                </button>
                <button
                routerLink="/admin/entreprises-commanditaires"
                class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('admin/entreprises-commanditaires')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3 h-6 w-6 flex-shrink-0"
                  [ngClass]="iconClasses('admin/entreprises-commanditaires')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >

                  <path stroke-linecap="round"
                   stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />


                </svg>
                Commanditaires
              </button>
              <div class="mt-1 space-y-1 pl-5">
              <button
              routerLink="/admin/sous-groupes"
              class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
              [ngClass]="navElementClasses('admin/sous-groups')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-3 h-6 w-6 flex-shrink-0"
                [ngClass]="iconClasses('admin/sous-group-commanditaires')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path stroke-linecap="round"
                 stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>
              Subgroups
            </button>
             </div>
                <button
                routerLink="/admin/entrepriseprestataire/list"
                class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('admin/entrepriseprestataire')"
              >
                <mat-icon [ngClass]="iconClasses('admin/entrepriseprestataire')" class="mr-3 h-6 w-6 flex-shrink-0">card_travel</mat-icon>
                Service provider
              </button>
              <div class="mt-1 space-y-1 pl-5">
                <button
                routerLink="/admin/equipe-audit/list"
                class="group flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium leading-none duration-200 ease-in-out"
                [ngClass]="navElementClasses('admin/equipe-audit')"
              >
                <mat-icon [ngClass]="iconClasses('admin/equipe-audit')" class="mr-3 h-6 w-6 flex-shrink-0">supervised_user_circle</mat-icon>
                Audit teams
              </button>
              </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <app-user-current-bar-card *ngIf="loggedUser && userEntrepriseInfo" [loggedUser]="loggedUser" [userEntrepriseInfo]="userEntrepriseInfo"></app-user-current-bar-card>
    </div>
  </div>

  <div class="flex flex-1 flex-col pl-64">

    <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-gray-900 items-center">

      <div class="text-white" style="font-size:2.1rem!important; font-weight:bolder;">Independantix</div>

      <div class="flex flex-1 items-center justify-end px-8">



        <button mat-button [matMenuTriggerFor]="menu" class="text-white mr-6 bg-gray-800  rounded-full py-1 pl-4 px-3 hover:bg-gray-700">

          <div class="flex flex-row items-center justify-start space-x-1">

      <span>
        Privacy notice documents
      </span>

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
      </svg>
     </div>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item
          (click)="downlaodPrivacyNotice()"
          >
            <div class="flex flex-row items-center justify-start space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              <span>
                Privacy notice
              </span>
             </div>
          </button>

          <button mat-menu-item
          (click)="downloadCGU()"
          >
            <div class="flex flex-row items-start justify-center space-x-2">
          <div class="w-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
          </div>
              <span class="w-full">
                General terms of use
              </span>
             </div>
          </button>

        </mat-menu>

        <button
          (click)="openContactDialog()"
          class="flex items-center space-x-2 text-sm text-white transition duration-200 ease-out hover:text-primary-dttl-green"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="block font-medium">Need help?</span>
        </button>

      </div>
    </div>
    <main class="flex-1 py-6">
      <div class="mx-auto max-w-7xl px-8">
        <router-outlet></router-outlet>
      </div>
    </main>
    <footer class="fixed bottom-0 text-end right-2">v1.0.13</footer>
  </div>
