import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EquipeAudit } from 'src/app/services/equipe-audit.service'

@Component({
    selector: 'app-equipe-audit-add-user-dialog',
    templateUrl: './equipe-audit-add-user-dialog.component.html',
    styleUrls: ['./equipe-audit-add-user-dialog.component.scss'],
    standalone: false
})
export class EquipeAuditAddUserDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EquipeAuditAddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { equipeAudit: EquipeAudit }
  ) {}

  ngOnInit(): void {
  }

  onSaved(equipeAudit: EquipeAudit): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}

