import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-base-error',
    templateUrl: './base-error.component.html',
    styleUrls: ['./base-error.component.scss'],
    standalone: false
})
export class BaseErrorComponent {
  @Input() errorCode!: string

}
