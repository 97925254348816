import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EntreprisesPrestataires } from 'src/app/services/entreprises-prestataires.service'


@Component({
    selector: 'app-entreprise-prestataire-remove-user-dialog',
    templateUrl: './entreprise-prestataire-remove-user-dialog.component.html',
    styleUrls: ['./entreprise-prestataire-remove-user-dialog.component.scss'],
    standalone: false
})
export class EntreprisePrestataireRemoveUserDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EntreprisePrestataireRemoveUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entreprisePrestataire: EntreprisesPrestataires }
  ) {}

  ngOnInit(): void {
  }

  onSaved(entreprisePrestataire: EntreprisesPrestataires): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}