import { Component, OnInit } from '@angular/core'
import { AbstractButtonComponent } from 'src/app/abstract/abstract-button.component'

@Component({
    selector: 'app-button-edit',
    templateUrl: './button-edit.component.html',
    styleUrls: ['./button-edit.component.scss'],
    standalone: false
})
export class ButtonEditComponent   
  extends AbstractButtonComponent
  implements OnInit {
  constructor() {
    super()
  }
  override get classes(): string[] {
    if (this.disabled)
      return [...super.classes, 'cursor-not-allowed', 'opacity-25']

    return [...super.classes, 'hover:text-red-700']
  }
}
