import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { UserInfo } from 'src/app/services/user.service';

@Component({
    selector: 'app-sous-groupes-users-list',
    templateUrl: './sous-groupes-users-list.component.html',
    styleUrls: ['./sous-groupes-users-list.component.scss'],
    standalone: false
})
export class SousGroupesUsersListComponent implements OnInit { 

  @Input() users!: UserInfo[] | undefined
  @Input() subgroupId!: number 
  constructor(
    private router: Router,
    protected subgroupeService: SousGroupeCommanditaireService,

  ) {}


ngOnInit(): void {
  
}


deleteUser(userId:number){
  this.subgroupeService.deleteUserFromSubgroupWithConfirmAndRedirectToUsersList(this.subgroupId!,userId)
}
}


