import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'
import {
  EquipeAudit, EquipeAuditService
} from 'src/app/services/equipe-audit.service'
import { PageItem } from 'src/app/services/utils'


@Component({
    selector: 'app-equipe-audit-update-form-page',
    templateUrl: './equipe-audit-update-form-page.component.html',
    styleUrls: ['./equipe-audit-update-form-page.component.scss'],
    standalone: false
})
export class EquipeAuditUpdateFormPageComponent implements OnChanges, OnInit {
  
  @Input() equipeAudit!: EquipeAudit
  @Output() onSaved: EventEmitter<EquipeAudit> = new EventEmitter<EquipeAudit>()


  pending: boolean = false

  default_id: number = 0
  equipeAuditUpdateForm = new FormGroup({
    reseau: new FormControl<string>('', { nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true }),
  })

  constructor(protected equipeAuditService: EquipeAuditService,
              protected entreprisesPrestatairesService: EntreprisesPrestatairesService) {
  }

  entreprisesPrestatairesSub: Subscription | undefined
  entreprisesPrestatairesPage: PageItem<EntreprisesPrestataires> | undefined

  pageUrl: string | undefined

  ngOnInit(): void {
    this.load()
  }

  load(): void {
    this.entreprisesPrestatairesSub?.unsubscribe()
    this.entreprisesPrestatairesSub = this.entreprisesPrestatairesService.getAll().subscribe({
      next: (x) => {
        this.entreprisesPrestatairesPage = x
      },
    })
  }


  ngOnChanges(): void {
    this.equipeAuditUpdateForm.get('id')?.setValue(this.equipeAudit.id)
    this.equipeAuditUpdateForm.get('name')?.setValue(this.equipeAudit.name)
    this.equipeAuditUpdateForm.get('reseau')?.setValue(this.equipeAudit.reseau)
  }

  onSubmit(): void {
    if (this.equipeAuditUpdateForm.invalid) {
      alert('Formulaire non valide')
      return
    }

    this.pending = true

    let data = this.equipeAuditUpdateForm.value

    this.equipeAuditService.updateEquipeAudit(data).subscribe({
      next: (equipeAudit: EquipeAudit) => {
        this.pending = false
        this.onSaved.emit(equipeAudit)
      },
      error: (res) => {
        this.pending = false
        alert("Cannot update audit team because there are still users in it")},
    })

  }
}
