import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';

@Component({
    selector: 'app-sous-groupes-update-page',
    templateUrl: './sous-groupes-update-page.component.html',
    styleUrls: ['./sous-groupes-update-page.component.scss'],
    standalone: false
})
export class SousGroupesUpdatePageComponent  implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected subgroupService: SousGroupeCommanditaireService,
    protected router: Router
  ) {}

  subgroup!: SousGroupeCommanditaire | undefined 
  subgroupSub: Subscription | undefined 



  ngOnInit(): void {
    const obs = this.route.paramMap.pipe(
        map(params => params.get('id')!),
        mergeMap(id =>this.subgroupService.getId(+id))
             )

        this.subgroupSub?.unsubscribe()

        this.subgroupSub = obs.subscribe({
          next: (response: SousGroupeCommanditaire) => {
            this.subgroup = response 
          },
          error: (res) => {
            alert("Failed to load subgroup" + res)},
        })
       }

  ngOnDestroy(): void {
    this.subgroupSub?.unsubscribe()
  }


}