import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Subscription } from 'rxjs';
import { MissionStatusMessage } from 'src/app/services/mission-status-message.service';
import { Mission, MissionService } from 'src/app/services/mission.service'


@Component({
    selector: 'app-mission-confirm-action-dialog',
    templateUrl: './mission-confirm-action-dialog.component.html',
    styleUrls: ['./mission-confirm-action-dialog.component.scss'],
    standalone: false
})
export class MissionConfirmActionDialogComponent  implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MissionConfirmActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { missionId: number; action: string },
    protected missionService: MissionService,
  ) {}

  headerMessage: string|undefined
  functionToCall:any
  actionSub: Subscription | undefined


  changeStatusForm = new FormGroup({
     content: new FormControl<string>('',  { validators: Validators.required, nonNullable: false }),
  });


  ngOnInit(): void {
    switch (this.data.action) {
      case 'delete':
        this.functionToCall = this.deleteMission
        this.headerMessage = "Are you sure that you want to delete this mission?"
        break;
        }
  }



  deleteMission(messageData:Partial<MissionStatusMessage>): void {
    this.actionSub?.unsubscribe()
    this.actionSub = this.missionService.deleteMission(this.data.missionId).subscribe(
      {next:()=> {
        this.close()
        this.missionService.goToMissionListPage()
        },
        error:()=> alert("Something went wrong, please refresh or contact Deloitte support")}
    )
  }


  performAction(): void {
      this.functionToCall()

  }

  close(): void {
    this.actionSub?.unsubscribe()
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.actionSub?.unsubscribe()
  }


}









