import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormattedReportType, Report, ReportGeneratedInfo, ReportsService, ReportType } from 'src/app/services/reports.service';
import { Task, TaskService } from 'src/app/services/task.service';
import { browserRefresh } from 'src/app/app.component';



@Component({
    selector: 'app-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss'],
    standalone: false
})
export class ReportsListComponent {

  browserRefresh = browserRefresh

  constructor(
    protected reportsService: ReportsService,
    protected taskService: TaskService,
  ) { }



  reportType = ReportType
  formattedReportType = FormattedReportType
  generateReportSub: Subscription | undefined
  @Input() reports!: Report[]

  ngOnInit(): void {
    this.clearSessionStorageOnBrowserRefresh()
  }




  generateReportAndSetPendingDownload(type: string): void {
    this.setIsPendingDownloadForReportIndex(type)
    this.generateReportBasedOnType(type)

  }

  setIsPendingDownloadForReportIndex(type: string): void {
    sessionStorage.removeItem(`is_pending_download${type}`)
    sessionStorage.setItem(`is_pending_download${type}`, "true")

  }

  getIsPendingDownloadForReportIndex(type: string): boolean {
    return sessionStorage.getItem(`is_pending_download${type}`) === "true"

  }

  removeIsPendingDownloadForReportIndex(type: string): void {
    sessionStorage.removeItem(`is_pending_download${type}`)

  }

  ispendingGenerationAndDownload(report: Report): boolean {
    const latest_report = report.latest_report_generated_info
    const has_report_pending = (latest_report !== null) && (latest_report.is_successfull || latest_report.is_pending)

    return this.getIsPendingDownloadForReportIndex(report.type) && has_report_pending
  }

  clearSessionStorageOnBrowserRefresh(): void {
    if (browserRefresh) {
      sessionStorage.clear()
    }
  }



  generateReportBasedOnType(type: string): void {
    let generateReportObs = this.reportsService.generateReportBasedOnType(type)
    this.generateReportSub?.unsubscribe()
    if (generateReportObs)
      this.generateReportSub = generateReportObs.subscribe(
        {
          next: (reportInfo) => {

            this.taskService.onFirstSuccessReceived(reportInfo.task_id)
              .subscribe({
                next: () => {
                  this.reportsService.onReportGenerted(reportInfo.id),
                    this.removeIsPendingDownloadForReportIndex(type)
                },
              }),

              this.taskService.onFirstFailReceived(reportInfo.task_id)
                .subscribe({
                  next: (x: Task | undefined) => {
                    if (x) {
                      this.reportsService.onReportFailedToGenerateDueToTaskFail(x.id)
                      this.removeIsPendingDownloadForReportIndex(type)
                    }
                  },
                })
          },
          error: () => {
            this.removeIsPendingDownloadForReportIndex(type),
            alert('Failed to request report generation, Please report this incident to technical support')
          }
        }
      )
  }


  ngOnDestroy(): void {
    this.generateReportSub?.unsubscribe()
  }

}
