import { Component } from '@angular/core';

@Component({
    selector: 'app-mission-status-message',
    templateUrl: './mission-status-message.component.html',
    styleUrls: ['./mission-status-message.component.scss'],
    standalone: false
})
export class MissionStatusMessageComponent {

}
