import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { ContactDialogComponent } from 'src/app/dialogs/contact-dialog/contact-dialog.component'
import { AuthService } from 'src/app/services/auth.service'
import { DocumentationService } from 'src/app/services/documentation.service'
import { UserInfo, UserEntrepriseInfo, UserService , EntrepriseType } from 'src/app/services/user.service'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
  showAdminSubMenu: boolean = false
  powersSub: Subscription | undefined

  userIsPrestataire: boolean = false


  userSub: Subscription | undefined
  loggedUser: UserInfo | undefined
  entrepriseInfoSub: Subscription | undefined
  userEntrepriseInfo: UserEntrepriseInfo | undefined


  constructor(
    private router: Router,
    protected userService: UserService,
    protected authService: AuthService,
    public dialog: MatDialog,
    private docService : DocumentationService 

  ) {}

  ngOnInit(): void {
    this.load()
    this.loadLoggedUser() 
    this.loadUserEntrepriseInfo()

  }

  load(): void {
    this.powersSub?.unsubscribe()
    this.powersSub = this.userService.checkIfUserIsAdmin().subscribe({
      next: (x) => {
        if(x == true) {
          this.showAdminSubMenu = true
        } else {
          this.showAdminSubMenu = false
        }
      },
    })
  }

  entrepriseType = EntrepriseType

  loadLoggedUser(){
    this.userSub?.unsubscribe()
    this.userSub = this.userService.getCurrent().subscribe({
      next: (x) => {
        this.loggedUser = x
      },
    })
  }

  loadUserEntrepriseInfo(){
    this.entrepriseInfoSub?.unsubscribe()
    this.entrepriseInfoSub = this.userService.getCurrentUserEntrepriseInfo().subscribe({
      next: (x) => {
        this.userEntrepriseInfo = x
        if (this.userEntrepriseInfo.user_type == EntrepriseType.PRESTATAIRE){ 
          this.userIsPrestataire = true
        }
      },
    })
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe()
    this.entrepriseInfoSub?.unsubscribe()
  }

  currentUrlIncludes(name: string, strict: boolean = false): boolean {
    const currentUrl = this.router.url

    return strict ? currentUrl === name : currentUrl.includes(name)
  }

  navElementClasses(name: string, strict: boolean = false): Array<string> {
    return [
      this.currentUrlIncludes(name, strict)
        ? 'active-nav-element'
        : 'inactive-nav-element',
    ]
  }

  iconClasses(name: string, strict: boolean = false): Array<string> {
    return [
      this.currentUrlIncludes(name, strict) ? 'active-icon' : 'inactive-icon',
    ]
  }

  openContactDialog(): void {
    const missionId: number | null = this.getMissionId()

    this.dialog.open(ContactDialogComponent, {
      data: { missionId: missionId },
      panelClass: 'max-w-2xl',
    })
  }





  downlaodPrivacyNotice():void{
    this.docService.downloadPrivacyNoticeGuide()

    }


  downloadCGU():void{
    this.docService.downloadCGU()

  }


  getMissionId(): number | null {
    const currentUrl: string = this.router.url

    const occ = currentUrl.match(/\/projects\/([0-9]+)([\/a-zA-Z]+)?/)

    if (!occ) return null

    const missionId: number = +occ[1]

    return missionId
  }
}
