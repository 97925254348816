import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Subscription } from 'rxjs';
import { MissionStatusMessage } from 'src/app/services/mission-status-message.service';
import { Mission, MissionService } from 'src/app/services/mission.service'

@Component({
    selector: 'app-change-mission-status-dialog',
    templateUrl: './change-mission-status-dialog.component.html',
    styleUrls: ['./change-mission-status-dialog.component.scss'],
    standalone: false
})

export class ChangeMissionStatusDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeMissionStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { missionId: number; action: string  },
    protected missionService: MissionService

  ) {}

  isMessageRequired: boolean = false

  headerMessage: string|undefined
  functionToCall:any
  changeStatusSub: Subscription | undefined

  is_pending :boolean = false


  changeStatusForm = new FormGroup({
     content: new FormControl<string>('', { nonNullable: false }),
  });


  ngOnInit(): void {
    switch (this.data.action) {
      case 'submit':
        this.functionToCall = this.submitMission
        break;
      case 'accept':
        this.functionToCall = this.acceptMission
        break;
       case 'reject':
       { this.functionToCall = this.rejectMission
        this.makeMessageRequired()}
        break;
      case 'requestAdditionalInfo':
        this.functionToCall = this.requestAdditionalInfo
        break;
        case 'cancel':
          this.functionToCall = this.cancelMission
          break;
      case 'putMissionBackToBeCompletedByAudit':
        this.functionToCall = this.putMissionBackToBeCompletedByAudit
        break;
        }
  }




  makeMessageRequired():void{
    this.changeStatusForm.get('content')?.setValidators(Validators.required);
    this.isMessageRequired=true
  }


  submitMission(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.submitMissionToClient(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
        error:()=> {alert("Something went wrong, please refresh or contact Deloitte support"),
                    this.setIsPending(false)}
      }
    )
  }
  requestAdditionalInfo(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.requestAdditionalInfo(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
        error:()=> {alert("Something went wrong, please refresh or contact Deloitte support"),
                    this.setIsPending(false)}

      }
    )
  }

  putMissionBackToBeCompletedByAudit(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.putMissionToBeCompletedByTheAuditAfterAcceptanceOrRejection(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
        error:()=>  {alert("Something went wrong, please refresh or contact Deloitte support"),
                    this.setIsPending(false)}
      }

    )
  }

  acceptMission(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.acceptMission(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
        error:()=>{alert("Something went wrong, please refresh or contact Deloitte support"),
                   this.setIsPending(false)}
       }

    )
  }

  rejectMission(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.rejectMission(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
        error:()=> {alert("Something went wrong, please refresh or contact Deloitte support"),
                    this.setIsPending(false)}
      }



    )
  }

  cancelMission(messageData:Partial<MissionStatusMessage>): void {
    this.changeStatusSub?.unsubscribe()
    this.changeStatusSub = this.missionService.cancelMission(this.data.missionId,messageData).subscribe(
      {next:()=> this.close(),
      error:()=> {alert("Something went wrong, please refresh or contact Deloitte support"),
                  this.setIsPending(false)}}
    )
  }

  onSubmit(): void {

    if (this.changeStatusForm.invalid) {
      alert('Form is not valid, please review any red inputs.')
      return
    }
    this.setIsPending(true)
    if (this.changeStatusForm.value){
      let message_data = this.changeStatusForm.value
      this.functionToCall(message_data)
     }

  }

  close(): void {
    this.setIsPending(false)
    this.changeStatusSub?.unsubscribe()
    this.dialogRef.close()
  }


  setIsPending(value:boolean):void{
    this.is_pending = value
  }




}
