import { Component } from '@angular/core';

@Component({
    selector: 'app-no-search-result-message',
    templateUrl: './no-search-result-message.component.html',
    styleUrls: ['./no-search-result-message.component.scss'],
    standalone: false
})
export class NoSearchResultMessageComponent {

}
