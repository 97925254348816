import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'
import { TitleService } from 'src/app/services/title.service'
import { PageItem } from 'src/app/services/utils'


@Component({
    selector: 'app-equipe-audit-admin-home-page',
    templateUrl: './equipe-audit-admin-home-page.component.html',
    styleUrls: ['./equipe-audit-admin-home-page.component.scss'],
    standalone: false
})
export class EquipeAuditAdminHomePageComponent implements OnInit {
  constructor(protected equipeAuditService: EquipeAuditService,
    private titleService: TitleService,

    public dialog: MatDialog,) {}

  equipeAuditSub: Subscription | undefined
  equipeAuditPage: PageItem<EquipeAudit> | undefined

  pageUrl: string | undefined

  ngOnInit(): void {
    this.load()
    this.titleService.setEquipesAuditTitle()
  }

  load(): void {
    this.equipeAuditSub?.unsubscribe()
    this.equipeAuditSub = this.equipeAuditService.getAll().subscribe({
      next: (x) => {
        this.equipeAuditPage = x
      },
    })
  }


  switchPage(url: string) {
    this.equipeAuditSub?.unsubscribe()
    this.equipeAuditSub = this.equipeAuditService.getPage(url).subscribe({
      next: (x) => {
        this.equipeAuditPage = x
      },
    })
  }


  ngOnDestroy(): void {
    this.equipeAuditSub?.unsubscribe()
  }
}
