import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService, EntrepriseCommanditaireType } from 'src/app/services/entreprisecommanditaire.service';
import { SousGroupeCommanditaire } from 'src/app/services/sous-groupe.service';
import { TitleService } from 'src/app/services/title.service';
import { PageItem } from 'src/app/services/utils';

@Component({
    selector: 'app-entreprises-commanditaires-detail-page',
    templateUrl: './entreprises-commanditaires-detail-page.component.html',
    styleUrls: ['./entreprises-commanditaires-detail-page.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesDetailPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private titleService : TitleService, 
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
    protected router: Router
  ) {}


  
  entreprise!: EntrepriseCommanditaire | undefined 
  entrepriseCommanditaireSub: Subscription | undefined 
  paginatedSubgroupes: PageItem<SousGroupeCommanditaire> | undefined
  subgroupesSub: Subscription | undefined


  ngOnInit(): void {
    this.loadEntreprise()

       }

  loadEntreprise():void { 
    const obs = this.route.paramMap.pipe(
      map(params => params.get('id')!),
      mergeMap(id =>this.entrepriseCommanditaireService.getId(+id))
           )

      this.entrepriseCommanditaireSub?.unsubscribe()

      this.entrepriseCommanditaireSub = obs.subscribe({
        next: (response: EntrepriseCommanditaire) => {
          this.entreprise = response 
          this.titleService.setTitle(response.name)
        },
        error: (res) => {
          if (res.status == 404){
            this.router.navigate(['/not-found']) 
          }
        //  alert("Failed to load entreprise" + res)
      },
      })
  }
  

       
  deleteEntreprise(): void {
    if (this.entreprise) {
      this.entrepriseCommanditaireService.deleteEntrepriseWithConfirmAndRedirectToList(this.entreprise)
    }
  }
  
  updateEntreprise(): void {
    if (this.entreprise) {
      this.entrepriseCommanditaireService.goToAdminEntrepriseUpdatePage(this.entreprise.id)
      
    }
  }


  entrepriseUsers(): void {
    if (this.entreprise) {
      this.entrepriseCommanditaireService.goToAdminEntrepriseUsersPage(this.entreprise.id)
      
    }
  }






  ngOnDestroy(): void {
    this.entrepriseCommanditaireSub?.unsubscribe()
  }

}