import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntrepriseCommanditaire } from 'src/app/services/entreprisecommanditaire.service';
import { SousGroupeCommanditaireService , SousGroupeType } from 'src/app/services/sous-groupe.service';



@Component({
    selector: 'app-sous-groupes-create',
    templateUrl: './sous-groupes-create.component.html',
    styleUrls: ['./sous-groupes-create.component.scss'],
    standalone: false
})
export class SousGroupesCreateComponent implements OnInit {

  @Input() entreprises!: Array<EntrepriseCommanditaire>
  constructor(
    protected subgroupService: SousGroupeCommanditaireService
  ) {}

  ngOnInit(): void {

  }

  pending: boolean = false
  activities =  SousGroupeType
  default_id: number = 0
   createSubgroupForm = new FormGroup({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    type: new FormControl<string>('', { nonNullable: false }),
    is_admin : new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
    is_validator : new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
    entreprise_commanditaire: new FormControl<number | null>(null, { validators: Validators.required, nonNullable: true }),
  })




  onConfirmSubgroupAdminPermission(event:Event):void{
    let valueBefore:boolean = !this.createSubgroupForm.get('is_admin')?.value
    let confirmMessage:string = (valueBefore == false)? 'Do you confirm granting admin viewing permission to this sub-group?':'Do you confirm removing admin viewing permission from this sub-group?'
    if (
      !confirm(
        confirmMessage
      )
    ){this.createSubgroupForm.get('is_admin')?.setValue(valueBefore)}
    else {
      if(valueBefore==true){ 
        this.resetIsValidator()
      }
      return
    }
    
  }



  checkIsAdmin():void{
    this.createSubgroupForm.get('is_admin')?.setValue(true)
  }

  resetIsValidator():void{
    this.createSubgroupForm.get('is_validator')?.setValue(false)
  }

  onConfirmSubgroupValidatorPermission(event:Event):void{
    let valueBefore:boolean = !this.createSubgroupForm.get('is_validator')?.value
    let confirmMessage:string = (valueBefore == false)? 'Do you confirm granting validator permission to this sub-group?':'Do you confirm removing validator permission from this sub-group?'
    if (
      !confirm(
        confirmMessage
      )
    ){this.createSubgroupForm.get('is_validator')?.setValue(valueBefore)}
    else {
      if(valueBefore==false){
        this.checkIsAdmin()
      }
      return
    }
    
  }


  onSubmit(): void {
    if (this.createSubgroupForm.invalid) {
      alert('Form is not valid')
      return
    }

   
    let subgroupData = this.createSubgroupForm.value
    if (subgroupData){
      
    }
    this.subgroupService.createSubgroupAndRedirectToList(subgroupData)
  }
}

