import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-add-equipe-audit-dialog',
    templateUrl: './add-equipe-audit-dialog.component.html',
    styleUrls: ['./add-equipe-audit-dialog.component.scss'],
    standalone: false
})
export class AddEquipeAuditDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddEquipeAuditDialogComponent>,
  ) {}

  ngOnInit(): void {}

  onSaved(): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}

