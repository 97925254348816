import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
    standalone: false
})
export class ForbiddenComponent implements OnInit { 

  constructor(
    private titleService: TitleService,

  ) { 

  }
  ngOnInit(): void {
  }
  



}
