import { Component, EventEmitter, OnInit, Output, ViewChild, SimpleChange } from '@angular/core';
import { MissionService, MissionStatut, Mission, MissionInfo, MissionActionsPermissions, CreateMission, YES_NO_BOOL , ServiceNature } from 'src/app/services/mission.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
// import { MatDatepicker } from '@angular/material/datepicker';
import { EntrepriseCommanditaire, EntrepriseCommanditaireType }from 'src/app/services/entreprisecommanditaire.service';
import { EquipeAuditService, EquipeAudit } from 'src/app/services/equipe-audit.service';
import { map, mergeMap, Subscription } from 'rxjs';
import { PageItem } from 'src/app/services/utils';
import { SousGroupeCommanditaire } from 'src/app/services/sous-groupe.service';
import { EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { countries } from 'src/assets/country_list'
import { currency } from 'src/assets/currency_list'
import { MissionUserFollower } from 'src/app/services/user.service';
import { ConversionRate, ConversionRateService } from 'src/app/services/conversion-rate.service';
import { TitleService } from 'src/app/services/title.service';
@Component({
    selector: 'app-project-create-mission-form',
    templateUrl: './project-create-mission-form.component.html',
    styleUrls: ['./project-create-mission-form.component.scss'],
    standalone: false
})
export class ProjectCreateMissionFormComponent implements OnInit{


  @Output() onSaved: EventEmitter<Mission> = new EventEmitter<Mission>()
  missionId:number|undefined
  missionSub: Subscription | undefined
  mission: Mission | undefined
  missionActionsPermissions!:MissionActionsPermissions


  isLocked: boolean = true
  activities = EntrepriseCommanditaireType
  YES_NO_BOOL = YES_NO_BOOL
  default_number!: number
  default_status = MissionStatut.TO_COMPLETE_BY_AUDIT
  default_equipe_audit!: EquipeAudit
  equipe_audit_choosen!: EquipeAudit
  default_date!: Date
  empty_list!: object[]
  default_thematic: EntrepriseCommanditaireType = EntrepriseCommanditaireType.FINANCE_COMPTA
  countries= countries
  currencies= currency
  nature_of_services_list = ServiceNature

  equipesAuditSub: Subscription | undefined
  equipesAuditOfUser: Array<EquipeAudit> | undefined
  entreprises_commanditaires: Array<EntrepriseCommanditaire> | undefined
  entreprisesCommanditairesWithoutPaginationSub : Subscription | undefined
  subGroups: Array<SousGroupeCommanditaire> | undefined
  subGroupsWithoutPaginationSub : Subscription | undefined
  missionSubgroupsSet: boolean = false
  conversionRateRetrieved : ConversionRate | undefined
  conversionRateInUse : ConversionRate | undefined
  conversionRateSub: Subscription | undefined
  noConversionRateFound: boolean = false
  BaseCurrencyisTargetCurrency:boolean = false

  createmissionForm = new FormGroup({
    name: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
    thematique: new FormControl<EntrepriseCommanditaireType>(this.default_thematic, { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),

    entreprise_commanditaire: new FormControl<number>(this.default_number, {validators: Validators.required, nonNullable: true }),
    entite_legale_commanditaire: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
    entreprise_prestataire: new FormControl<number>(this.default_number, {validators: Validators.required, nonNullable: true }),
    entite_legale_prestataire: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
    equipe_audit: new FormControl<number>(this.default_number, {validators: Validators.required, nonNullable: true }),
    sous_groupes: new FormControl<object[]>(this.empty_list, {validators: Validators.required, nonNullable: true }),

    description: new FormControl<string | null>(null, {nonNullable: true }),
    nature_du_service: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
    statut: new FormControl<MissionStatut>(this.default_status, { validators: Validators.required, nonNullable: true }),

    start_date: new FormControl<Date>(this.default_date, { validators: Validators.required, nonNullable: true }),
    end_date: new FormControl<Date>(this.default_date, { validators: Validators.required, nonNullable: true }),

    country: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),

    client_business_contact: new FormControl<string | null>(null, { validators: [Validators.maxLength(300)], nonNullable: false }),

    reference_commanditaire: new FormControl<string | null>(null, { nonNullable: false, validators: Validators.maxLength(300) }),
    reference_facturation: new FormControl<string | null>(null, { nonNullable: false, validators: Validators.maxLength(300) }),
    reference_prestataire: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),

    fees: new FormControl<number>(this.default_number, { validators: Validators.required, nonNullable: true }),
    currency: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    cv_euros: new FormControl<number>(this.default_number, { validators: Validators.required, nonNullable: true }),
    conversion_rate: new FormControl<number|null|undefined>(this.default_number, { nonNullable: false }),

    mission_users_following: new FormArray<FormGroup>([]),


  })

  pending: boolean = false

  constructor(protected missionService: MissionService,
              private titleService: TitleService,
              protected equipeAuditService: EquipeAuditService,
              protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
              protected conversionRateService : ConversionRateService,
              private route: ActivatedRoute,
              protected router: Router) {}




  getAuditTeamsOfUser():void {
    this.equipesAuditSub?.unsubscribe()
    this.equipesAuditSub = this.equipeAuditService.getAllEquipesOfUserWithoutPagination().subscribe({
      next: (x) => {
        this.equipesAuditOfUser = x
        this.createmissionForm.get('entreprise_prestataire')?.setValue(this.equipesAuditOfUser[0].entreprise_prestataire.id)
      },
    })
  }

  loadAuditTeamsOfUserBasedOnEntrepriseCommanditaire(entreprise_commanditaire_id:number):void {
    this.equipesAuditSub?.unsubscribe()
    this.equipesAuditSub = this.equipeAuditService.getEquipesAuditFromUserAndEntrepriseCommanditaireWithoutPagination(entreprise_commanditaire_id).subscribe({
      next: (x) => {
        this.equipesAuditOfUser = x
        if (x.length==1){
          this.createmissionForm.get('equipe_audit')?.setValue(this.equipesAuditOfUser[0].id)
        }
        this.createmissionForm.get('entreprise_prestataire')?.setValue(this.equipesAuditOfUser[0].entreprise_prestataire.id)
      },
    })
  }


  loadEntreprisesCommanditairesWithoutPagination(){
    this.entreprisesCommanditairesWithoutPaginationSub?.unsubscribe
    this.entreprisesCommanditairesWithoutPaginationSub = this.entrepriseCommanditaireService.getAllEntreprisesOfEquipesAuditOfLoggedUserWithoutPagination().subscribe({
      next: (response: Array<EntrepriseCommanditaire>) => {
        this.entreprises_commanditaires = response

      },
      error: (res) => {
        alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }



  buildFollowersForm(): FormGroup {
    return new FormGroup({
      first_name: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
      last_name: new FormControl<string>('', { validators: [Validators.required, Validators.maxLength(300)], nonNullable: true }),
      email: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(300),
        Validators.email,
      ]),
    })
  }

  setFollowersFormsWithValues(followers_list:Array<MissionUserFollower>):void{
    let followers_forms = []
    this.mission_users_following.removeAt(0)

    for (let i = 0; i < followers_list.length; i++) {
       let follower_form = this.buildFollowersForm()
       follower_form.get('first_name')?.setValue(followers_list[i].first_name)
       follower_form.get('last_name')?.setValue(followers_list[i].last_name)
       follower_form.get('email')?.setValue(followers_list[i].email)
       followers_forms.push(follower_form)
       this.mission_users_following.push(follower_form)
    }

  }



  get mission_users_following() {
    return this.createmissionForm.get('mission_users_following') as FormArray<FormGroup>
  }


  addFollower(): void {
    this.mission_users_following.push(this.buildFollowersForm())
  }

  removeFollower(i: number): void {
    if (confirm('Are you sure you want to remove the follower?')) {
      this.mission_users_following.removeAt(i)
    }
  }


  subscribeToEntrepriseCommanditaireSelected():void {
    this.createmissionForm.get('entreprise_commanditaire')?.valueChanges.subscribe(entreprise_commanditaire_id=> {
      this.subGroups = undefined
      this.loadSousGroupesOfEntrepriseCommanditaire(entreprise_commanditaire_id)
      this.loadAuditTeamsOfUserBasedOnEntrepriseCommanditaire(entreprise_commanditaire_id)
     }
      )
  }

  loadEquipesAndGroupesBasedOnEntrepriseWhenUpdating(entreprise_commanditaire_id:number):void {
        this.loadSousGroupesOfEntrepriseCommanditaire(entreprise_commanditaire_id)
        this.loadAuditTeamsOfUserBasedOnEntrepriseCommanditaire(entreprise_commanditaire_id)
  }

  loadSousGroupesOfEntrepriseCommanditaire(entrepriseId:number):void{
    const obs = this.entrepriseCommanditaireService.getSubgroupesWithoutPagination(entrepriseId)
      this.subGroupsWithoutPaginationSub?.unsubscribe()
      this.subGroupsWithoutPaginationSub = obs.subscribe({
        next: (response: Array<SousGroupeCommanditaire>) => {
          this.subGroups = response
          if(!this.editMission() || this.missionSubgroupsSet){
            if(this.subGroups.length==1){
              this.createmissionForm.get('sous_groupes')?.setValue([{'id': this.subGroups[0].id}])
            }else{
              this.createmissionForm.get('sous_groupes')?.reset()
              this.createmissionForm.get('sous_groupes')?.setValue([])
            }
            return
          }else if (this.mission) {
            this.createmissionForm.get('sous_groupes')?.setValue(this.mission.sous_groupes)
            this.missionSubgroupsSet = true
          }
        },
        error: (res) => {
          alert("Something went wrong, please refresh or contact Deloitte support")},
      })
  }


  goToMission(id: number): void {
    this.router.navigate(['/mission', id])
  }

  editMission():boolean {
    if (this.router.url == "/create-mission") {
      return false
    } else {
      return true
    }
  }

  loadMission():void{
      if (this.route.parent) {
        const obs = this.route.parent.params.pipe(
        map(params => { this.missionId = +params['id']
             return this.missionId }),
        mergeMap(missionId =>this.missionService.getMissionInfo(missionId))
             )

       this.missionSub?.unsubscribe()
       this.missionSub = obs.subscribe({
        next: (response: MissionInfo) => {
        this.mission = response.mission
        this.titleService.setEditMissionTitle(response.mission.name)
        this.missionActionsPermissions = response.mission_actions_permissions
        this.loadEquipesAndGroupesBasedOnEntrepriseWhenUpdating(response.mission.entreprise_commanditaire.id)
        this.initForm()
      },
      error: (res) => {
        alert("Something went wrong, please refresh or contact Deloitte support")},
      })
    }
  }

  subscribeToCurrencySelected():void {
    this.createmissionForm.get('currency')?.valueChanges.subscribe(currency=> {
      this.loadConversionRateForCurrency(currency)


     }

      )


  }





  get newConversionRateAvailable():boolean{
     if(!this.editMission()){
        return true
      }else if(!this.conversionRateInUse){
        return true
     }else{
      return (this.conversionRateRetrieved?.id!=this.mission?.conversion_rate?.id)
     }
   }

  get displayNoConversionRateWasFound() : boolean | undefined{
      return this.noConversionRateFound && this.createmissionForm?.get('fees')?.valid
     }

  get displayMoreRecentConversionRateWasFound() : boolean | undefined{
      return  (this.conversionRateInUse?.devise_target == this.conversionRateRetrieved?.devise_target)
       && (this.conversionRateInUse?.rate != this.conversionRateRetrieved?.rate)
     }




  get displayConvertAutomatically_If_BaseCurrencyisNotTargetCurrency() : boolean | undefined{
      return this.displayMoreRecentConversionRateWasFound ||
             !this.conversionRateInUse &&
             this.conversionRateRetrieved &&
             this.createmissionForm?.get('fees')?.valid
                 }




  get displayConvertAutomatically(): boolean | undefined{
    return this.displayConvertAutomatically_If_BaseCurrencyisNotTargetCurrency && !this.BaseCurrencyisTargetCurrency
               }


   CheckIfBaseCurrencyisTargetCurrency(rate :ConversionRate): boolean{
    return rate.devise_source == rate.devise_target
  }


  setValueofCVEuros(value: number): void {
   this.createmissionForm.get('cv_euros')?.setValue(value)
   }

   disableCVEuros(): void {
    this.createmissionForm.get('cv_euros')?.disable()
   }

   enableCVEuros(): void {
    this.createmissionForm.get('cv_euros')?.enable()
   }

   resetCVEuros(): void {
    this.createmissionForm.get('cv_euros')?.reset()
   }

   getValueOfFees(): number | undefined {
   return this.createmissionForm.get('fees')?.value
   }



  loadConversionRateForCurrency(currency:string):void{
     this.conversionRateRetrieved = undefined ,
     this.conversionRateSub?.unsubscribe()
     this.conversionRateSub = this.conversionRateService.getLatestSavedConversionRateForCurrency(currency).subscribe({
      next: (x) => {
        this.conversionRateRetrieved = x ,
         this.noConversionRateFound = false

     if (this.CheckIfBaseCurrencyisTargetCurrency(x)) {
      this.BaseCurrencyisTargetCurrency = true
      let fees = this.getValueOfFees()
      if (fees)
      this.setValueofCVEuros(fees)
      this.disableCVEuros()
     }else{
      this.BaseCurrencyisTargetCurrency=false
      this.enableCVEuros()
     }

        } ,
      error: () => {this.noConversionRateFound = true , this.conversionRateRetrieved = undefined}
     })

  }

  get currency(){
    return  this.createmissionForm.get('currency')?.value
  }

    resetCoversionRateInUse():void{
        this.createmissionForm.get('conversion_rate')?.reset()
        this.conversionRateInUse = undefined
     }


     onCurrencyChange():void{
      this.resetCoversionRateInUse()
   }


   onFeesChange():void{
    this.resetCoversionRateInUse()

    if (this.BaseCurrencyisTargetCurrency) {
      let amount = this.getValueOfFees()
      if (amount){
        this.setValueofCVEuros(amount)
     }else{
      this.resetCVEuros()
     }
    }
 }

 onCvEurosChange():void{
  this.resetCoversionRateInUse()
}



  setConversionRateWhileUpdating():void{
    if(this.mission?.conversion_rate){
      this.createmissionForm.get('conversion_rate')?.setValue(this.mission.conversion_rate.id)
      this.conversionRateInUse = this.mission.conversion_rate
    }else{
      this.createmissionForm.get('conversion_rate')?.reset()
    }
  }

  autoConvertToEuros():void{

    if (this.conversionRateRetrieved){
      let sourceFees:number | undefined = this.createmissionForm.get('fees')?.value
      if (sourceFees) {
        let cv_amount:number = sourceFees /this.conversionRateRetrieved.rate
        let cv_amount_rounded = Math.round(cv_amount * 100) / 100
        this.createmissionForm.get('cv_euros')?.setValue(cv_amount_rounded)
        this.createmissionForm.get('conversion_rate')?.setValue(this.conversionRateRetrieved.id)
        this.conversionRateInUse = this.conversionRateRetrieved
     }
    }else{
      alert("Something went wrong, please refresh or contact Deloitte support")
    }

  }


  initForm(): void {
    if (this.mission) {
      this.createmissionForm.get('reference_commanditaire')?.setValue(this.mission.reference_commanditaire)
      this.createmissionForm.get('reference_facturation')?.setValue(this.mission.reference_facturation)
      this.createmissionForm.get('client_business_contact')?.setValue(this.mission.client_business_contact)
      this.createmissionForm.get('reference_prestataire')?.setValue(this.mission.reference_prestataire)
      this.createmissionForm.get('name')?.setValue(this.mission.name)
      this.createmissionForm.get('thematique')?.setValue(this.mission.thematique)
      this.createmissionForm.get('nature_du_service')?.setValue(this.mission.nature_du_service)
      this.createmissionForm.get('description')?.setValue(this.mission.description)
      this.createmissionForm.get('start_date')?.setValue(this.mission.start_date)
      this.createmissionForm.get('end_date')?.setValue(this.mission.end_date)
      this.createmissionForm.get('fees')?.setValue(this.mission.fees)
      this.createmissionForm.get('currency')?.setValue(this.mission.currency)
      this.createmissionForm.get('cv_euros')?.setValue(this.mission.cv_euros)
      this.createmissionForm.get('entite_legale_commanditaire')?.setValue(this.mission.entite_legale_commanditaire)
      this.createmissionForm.get('entite_legale_prestataire')?.setValue(this.mission.entite_legale_prestataire)
      this.createmissionForm.get('country')?.setValue(this.mission.country)
      this.createmissionForm.get('entreprise_commanditaire')?.setValue(this.mission.entreprise_commanditaire.id)
      this.createmissionForm.get('entreprise_commanditaire')?.disable()
      this.createmissionForm.get('entreprise_prestataire')?.setValue(this.mission.entreprise_prestataire.id)
      this.createmissionForm.get('equipe_audit')?.setValue(this.mission.equipe_audit.id )
      this.createmissionForm.get('equipe_audit')?.disable()
      this.createmissionForm.get('sous_groupes')?.setValue(this.mission.sous_groupes)
      this.setFollowersFormsWithValues(this.mission.mission_users_following)
      this.setConversionRateWhileUpdating()
    }


  }

  ngOnInit(): void {
    this.loadEntreprisesCommanditairesWithoutPagination()
    this.subscribeToCurrencySelected()
    if (this.editMission()) {
      this.loadMission()

    } else {
      this.titleService.setCreateMissionTitle()
      this.subscribeToEntrepriseCommanditaireSelected()
    }
  }


  createMission(data: Partial<CreateMission>) {
    this.missionService.createMission(data).subscribe({
      next: (mission: Mission) => {
        this.pending = false
        this.onSaved.emit(mission)
        this.goToMission(mission.id)
      },
      error: (res) => {
        this.pending = false
        alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }

  updateMission(data: Partial<CreateMission>) {
    this.missionService.updateMission(data, this.missionId!).subscribe({
      next: (mission: Mission) => {
        this.pending = false
        this.onSaved.emit(mission)
        this.goToMission(mission.id)
      },
      error: (res) => {
        this.pending = false
        alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }

  onSubmit(): void {

    if (this.createmissionForm.invalid) {

      alert('Form is not valid, please review any red input or contact support')
      return
    }
    this.pending = true
    this.createmissionForm.enable()
    let data = this.createmissionForm.value
    if (!this.editMission()) {
      this.createMission(data)
    } else {
      this.updateMission(data)
    }
  }

  ngOnDestroy(): void {
    this.equipesAuditSub?.unsubscribe()
    this.subGroupsWithoutPaginationSub?.unsubscribe()
    this.missionSub?.unsubscribe()
    this.conversionRateSub?.unsubscribe()
    this.entreprisesCommanditairesWithoutPaginationSub?.unsubscribe
  }


  compareObjects(o1: SousGroupeCommanditaire, o2: SousGroupeCommanditaire): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.id === o2.id : o2 === o2;
  }

}

