import { Component } from '@angular/core';

@Component({
    selector: 'app-project-create-mission',
    templateUrl: './project-create-mission.component.html',
    styleUrls: ['./project-create-mission.component.scss'],
    standalone: false
})
export class ProjectCreateMissionComponent {

}
