import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService, EntrepriseCommanditaireType } from 'src/app/services/entreprisecommanditaire.service';

@Component({
    selector: 'app-entreprises-commanditaires-update',
    templateUrl: './entreprises-commanditaires-update.component.html',
    styleUrls: ['./entreprises-commanditaires-update.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesUpdateComponent  implements OnChanges {
  @Input() entreprise!: EntrepriseCommanditaire

  constructor(
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService
  ) {}



  activities =  EntrepriseCommanditaireType

  updateEntrepriseForm = new FormGroup({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    type: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
  })



  ngOnChanges(): void {
    this.updateEntrepriseForm.get('name')?.setValue(this.entreprise.name)
    this.updateEntrepriseForm.get('type')?.setValue(this.entreprise.type)
  }


  onSubmit(): void {
    if (this.updateEntrepriseForm.invalid) {
      alert('Form is not valid')
      return
    }

    let entreprise_data = this.updateEntrepriseForm.value
    this.entrepriseCommanditaireService.updateEntrepriseAndRedirectToDetail(entreprise_data, this.entreprise.id)
  }






}
