import { Component, Input, OnInit } from '@angular/core';
import { EntrepriseCommanditaire } from 'src/app/services/entreprisecommanditaire.service';


@Component({
    selector: 'app-entreprises-commanditaires-detail',
    templateUrl: './entreprises-commanditaires-detail.component.html',
    styleUrls: ['./entreprises-commanditaires-detail.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesDetailComponent implements OnInit {

  @Input() entreprise!: EntrepriseCommanditaire

  
  ngOnInit(): void {}

  

}
