import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { UserInfo } from 'src/app/services/user.service';

@Component({
    selector: 'app-entreprises-commanditaires-users-list',
    templateUrl: './entreprises-commanditaires-users-list.component.html',
    styleUrls: ['./entreprises-commanditaires-users-list.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesUsersListComponent implements OnInit { 

  @Input() users!: UserInfo[] | undefined
  @Input() entrepriseId!: number 
  constructor(
    private router: Router,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,

  ) {}


ngOnInit(): void {
  
}


deleteUser(userId:number){
  this.entrepriseCommanditaireService.deleteUserFromEntrepriseWithConfirmAndRedirectToList(this.entrepriseId,userId)
}
}


