import { Component } from '@angular/core';

@Component({
    selector: 'app-entreprises-commanditaires-create-page',
    templateUrl: './entreprises-commanditaires-create-page.component.html',
    styleUrls: ['./entreprises-commanditaires-create-page.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesCreatePageComponent {

}
