import { Component, OnInit } from '@angular/core'
import { mergeMap, of, Subscription, switchMap } from 'rxjs'
import {
  EntrepriseCommanditaire,
  EntrepriseCommanditaireService,
  EntrepriseCommanditaireType,
} from 'src/app/services/entreprisecommanditaire.service'
import { PageItem } from 'src/app/services/utils'


@Component({
    selector: 'app-entreprises-commanditaires-list-page',
    templateUrl: './entreprises-commanditaires-list-page.component.html',
    styleUrls: ['./entreprises-commanditaires-list-page.component.scss'],
    standalone: false
})
export class EntreprisesCommanditairesListPageComponent implements OnInit {

  entreprisesCommanditairesSub: Subscription | undefined
  paginatedEntreprises: PageItem<EntrepriseCommanditaire> | undefined


  constructor(
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService
  ) {}

  has_filter:boolean = false

  
  ngOnInit(): void {
    this.load()
  }


  load(): void {
    this.entreprisesCommanditairesSub?.unsubscribe()
    this.entreprisesCommanditairesSub = this.entrepriseCommanditaireService.getAll().subscribe({
      next: (response: PageItem<EntrepriseCommanditaire>) => {
        this.paginatedEntreprises = response 

      },
      error: (res) => {
        alert("Failed to load entreprises" + res)},
    })
  }

  switchPage(url: string) {
    this.entreprisesCommanditairesSub?.unsubscribe()
    this.entreprisesCommanditairesSub = this.entrepriseCommanditaireService.getPage(url).subscribe({
      next: (response: PageItem<EntrepriseCommanditaire>) =>
        (this.paginatedEntreprises = response),
      error: (_) =>
        alert(
          "Une erreur s'est produite lors de la récupération des entreprises."
        ),
    })
  }

  goToEntreprise(id: number): void {
     this.entrepriseCommanditaireService.goToAdminEntrepriseDetailPage(id)
  }

  goToEntrepriseCreate(): void {
    this.entrepriseCommanditaireService.goToAdminEntrepriseCreatePage()

  }

  ngOnDestroy() {
    this.entreprisesCommanditairesSub?.unsubscribe()
  }

}
