import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { EquipeAudit , EquipeAuditService } from 'src/app/services/equipe-audit.service'
import { PageItem } from 'src/app/services/utils'
import { UserInfo, UserService } from 'src/app/services/user.service'
import { EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'

@Component({
    selector: 'app-equipe-audit-add-user-form',
    templateUrl: './equipe-audit-add-user-form.component.html',
    styleUrls: ['./equipe-audit-add-user-form.component.scss'],
    standalone: false
})
export class EquipeAuditAddUserFormComponent implements OnChanges, OnInit {
  
  @Input() equipeAudit!: EquipeAudit
  @Output() onSaved: EventEmitter<EquipeAudit> = new EventEmitter<EquipeAudit>()


  pending: boolean = false

  default_id: number = 0

  addUserForm = new FormGroup({
    equipe_id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
  })

  constructor(protected equipeAuditService: EquipeAuditService,
              protected userService : UserService,
              protected entreprisesPrestatairesService: EntreprisesPrestatairesService) {
  }

  usersSub: Subscription | undefined
  usersPage: PageItem<UserInfo> | undefined

  ngOnInit(): void {
    this.load()
  }

  getUserTrueEmail(userInfo : UserInfo):string | undefined{
    return this.userService.getTrueEmailFromUserInfo(userInfo)
}

  load(): void {
    this.usersSub?.unsubscribe()
    this.usersSub = this.entreprisesPrestatairesService.getAllUsers(this.equipeAudit.entreprise_prestataire.id).subscribe({
      next: (x) => {
        this.usersPage = x
      },
    })
  }

  ngOnChanges(): void {
    this.addUserForm.get('equipe_id')?.setValue(this.equipeAudit.id)
  }

  onSubmit(): void {
    if (this.addUserForm.invalid) {
      alert('Formulaire non valide')
      return
    }

    this.pending = true

    let data = this.addUserForm.value

    if (data.equipe_id && data.id) {
      this.equipeAuditService.addUser(data.equipe_id, data.id).subscribe({
        next: (equipeAudit: EquipeAudit) => {
          this.pending = false
          this.onSaved.emit(equipeAudit)
        },
        error: (res) => {
          this.pending = false
          alert("Cannot update entreprise" + res)},
      })
    }

  }

  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
  }
}
