import { Component, Input, OnInit } from '@angular/core';
import { List } from 'postcss/lib/list';
import { Subscription } from 'rxjs';
import { MissionEventService , MissionEvent } from 'src/app/services/mission-event.service';
import { MissionStatut } from 'src/app/services/mission.service';

@Component({
    selector: 'app-project-actuality-sidebar',
    templateUrl: './project-actuality-sidebar.component.html',
    styleUrls: ['./project-actuality-sidebar.component.scss'],
    standalone: false
})


export class ProjectActualitySidebarComponent implements OnInit{

  @Input() missionId!:number

  MissionEvents:MissionEvent[] | undefined
  MissionEventsSub:Subscription | undefined
  missionStatus = MissionStatut

  

  constructor(protected eventsService : MissionEventService){}


  ngOnInit(): void {
    this.getAllEvents()
  }

  getAllEvents(){
    this.MissionEventsSub?.unsubscribe()
    this.MissionEventsSub = this.eventsService.getAllEventsOfMission(this.missionId).subscribe({
      next: (response: MissionEvent[]) => {
        this.MissionEvents = response 

      },
      error: (res) => {
        alert("Failed to load events" + res)},
    })
    
  }


  mission_status =
  [{libelle: "create",
    text: "Creation",
    date: new Date()}, 
    {libelle: "new_doc_submited",
    text: "New documents submited",
    date: new Date()},
    {libelle: "edit_data",
    text: "Edit data",
    date: new Date()},
    {libelle: "new_doc_submited",
    text: "New documents submited",
    date: new Date()}, 
    // {libelle: "canceled",
    // text: "Demande annulée",
    // date: new Date()},
    {libelle: "denied",
    text: "Request denied",
    date: new Date()},
    {libelle: "client_ask_new_docs",
    text: "New documents requested",
    date: new Date()},
    {libelle: "edit_data",
    text: "Data edited",
    date: new Date()},
    {libelle: "new_doc_submited",
    text: "New documents submited",
    date: new Date()}, 
    {libelle: "client_validation",
    text: "Client validation",
    date: new Date()}
  ]

  ngOnDestroy() {
    this.MissionEventsSub?.unsubscribe()
  }

}
