import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { map, mergeMap, Subscription } from 'rxjs'
import {  ClientService } from 'src/app/services/client.service'
import { Mission, MissionInfo, MissionService, MissionActionsPermissions, MissionStatut, FormattedMissionStatut } from 'src/app/services/mission.service'
import { MissionUserFollower, UserEntrepriseInfo, UserService ,EntrepriseType, UserInfo } from 'src/app/services/user.service'
import { MissionCancelDialogComponent } from 'src/app/dialogs/mission-cancel-dialog/mission-cancel-dialog.component'
import { ProjectMissionComponent } from '../project-mission/project-mission.component'
import { SousGroupeCommanditaire, SousGroupeCommanditaireInfo } from 'src/app/services/sous-groupe.service'




export interface MissionModel {
  util_reference: number,
  office_reference: string,
  billing_reference: string,
  easy_reference: string,
  procuring_entity_name: string,
  beneficiary_entity_name: string,
  mission_name: string,
  themes: string,
  status: string[],
  type_of_service: string,
  description: string,
  start_date: Date,
  end_date: Date,
  fees: number,
  currency: string,
  cv_euros: number,
  country: string,
  SACC_type: string,
}



@Component({
    selector: 'app-project-summary',
    templateUrl: './project-summary.component.html',
    styleUrls: ['./project-summary.component.scss'],
    standalone: false
})
export class ProjectSummaryComponent implements OnInit {
  mission!: Mission
  missionActionsPermissions!:MissionActionsPermissions
  missionSub: Subscription | undefined

  EntrepriseInfoSub: Subscription | undefined
  userEntrepriseInfo : UserEntrepriseInfo | undefined


  entrepriseType = EntrepriseType



  sousGroupesWithUsers : SousGroupeCommanditaireInfo[] | undefined
  sousGroupesWIthUsersSub : Subscription  | undefined

  missionFollowers : MissionUserFollower[] | undefined
  missionFollowersSub : Subscription | undefined







  dateVal  =new Date();
  missionId!: number

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    protected missionService: MissionService,
    protected clientService: ClientService,
    protected userService: UserService,
    private projectMissionComponent: ProjectMissionComponent
  ) {}

  ngOnInit(): void {
    this.loadMission()
  }

  join_sous_groupes_names_to_string(sous_groupes:SousGroupeCommanditaire[]) {
    return sous_groupes.map(x => x.name).join(", ")
  }



  loadMission(){
    if (this.route.parent) {
      const obs = this.route.parent.params.pipe(
      map(params => { this.missionId = +params['id']
           return this.missionId }),
      mergeMap(missionId =>this.missionService.getMissionInfo(missionId))
           )

     this.missionSub?.unsubscribe()
     this.missionSub = obs.subscribe({
      next: (response: MissionInfo) => {
      this.mission = response.mission ,
      this.missionActionsPermissions = response.mission_actions_permissions  ,
      this.loadMissionValidatorsAndFollowers()
    },
    error: (res) => {
      alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }
}

missionStatus = MissionStatut
FormattedMissionStatus = FormattedMissionStatut


 getStatusPalette():string{
   if (this.mission.statut == this.missionStatus.TO_COMPLETE_BY_AUDIT){
    return "yellow"
   }
    else if (this.mission.statut == this.missionStatus.TO_BE_VALIDATED_BY_THE_CLIENT){
    return "teal"
   }else if (this.mission.statut == this.missionStatus.ACCEPTED){
    return "green"
   }else if (this.mission.statut == this.missionStatus.REJECTED){
    return "red"
   }else if (this.mission.statut == this.missionStatus.CANCLED){
    return "orange"
   }else{
    return ""
   }
 }


 openStatusChengeDialog(action:string): void {
    if(this.missionId)
    this.missionService.openChangeMissionStatusDialog(this.missionId , action )
 }


 openConfirmActionDialog(action:string): void {
  if(this.missionId)
  this.missionService.openMissionConfirmActionDialog(this.missionId ,action )
}





  openCancelDialog(): void {
    this.dialog.open(MissionCancelDialogComponent, {
      panelClass: 'max-w-2xl',
      data: {
        mission_id: 1,
        client_name: "Casa",
        // mission_id: this.mission.id,
        // client_name: this.client?.name,
      },
    })
  }

  openEditMissionForm(): void {
    this.missionService.goToMissionEditingPage(this.missionId)
  }


  loadMissionSousGroupesWithUsers():void{
    this.sousGroupesWIthUsersSub?.unsubscribe()
    this.sousGroupesWIthUsersSub = this.missionService.getMissionSousGroupesWithUsers(this.mission.id).subscribe({
      next:(res)=>  { this.sousGroupesWithUsers = res },
      error:()=> alert("Something went wrong, please refresh or contact Deloitte support")
     })
  }

  getuUserTrueEmail(userInfo : UserInfo):string | undefined{
    return this.userService.getTrueEmailFromUserInfo(userInfo)
}


  loadMissionValidatorsAndFollowers():void{
    this.EntrepriseInfoSub?.unsubscribe()
    this.EntrepriseInfoSub = this.userService.getCurrentUserEntrepriseInfo().subscribe({
      next: (res) => {
        this.userEntrepriseInfo = res
        if (res && res.user_type == this.entrepriseType.PRESTATAIRE){
          this.loadMissionFollowers()
          this.loadMissionSousGroupesWithUsers()
        }
        else if (res && res.user_type == this.entrepriseType.COMMANDITAIRE){
          this.loadMissionSousGroupesWithUsers()
        }
      },
      error :() => {
        alert("Something went wrong, please refresh or contact Deloitte support")
      }

    })

  }

  loadMissionFollowers():void{
    this.missionFollowersSub?.unsubscribe()
    console.error = function() {}
    this.missionFollowersSub = this.missionService.getMissionFollowers(this.mission.id).subscribe({
      next:(res)=>  { this.missionFollowers = res  },
      error:()=> alert("Something went wrong, please refresh or contact Deloitte support")
     })
  }



  ngOnDestroy() {
    this.missionSub?.unsubscribe()
  }
}
