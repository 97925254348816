import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';

@Component({
    selector: 'app-do-not-exist',
    templateUrl: './do-not-exist.component.html',
    styleUrls: ['./do-not-exist.component.scss'],
    standalone: false
})
export class DoNotExistComponent  implements OnInit{
  constructor(
    private titleService: TitleService,
  ){ 

  }
  ngOnInit(): void {
    
  }

 

}
