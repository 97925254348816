import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MissionDocumentService, AllowedFileExtensions, AllowedFileTypes , MaximumFileSizeInMB , MissionDocumentCategory} from 'src/app/services/mission-document.service';


@Component({
    selector: 'app-mission-document-upload-dialog',
    templateUrl: './mission-document-upload-dialog.component.html',
    styleUrls: ['./mission-document-upload-dialog.component.scss'],
    standalone: false
})
export class MissionDocumentUploadDialogComponent implements OnInit {

  uploadedFile: File | undefined

  allowed_file_types = AllowedFileTypes
  allowed_file_extensions = AllowedFileExtensions
  allowed_file_extensions_html_view = '.'+this.allowed_file_extensions.join(', .')
  documentCategories = MissionDocumentCategory

  constructor(
    public dialogRef: MatDialogRef<MissionDocumentUploadDialogComponent>,
    protected missionDocumentService: MissionDocumentService ,
    @Inject(MAT_DIALOG_DATA) public data: { missionId: number }

  ) { }

  ngOnInit(): void {
  }

  fileBeingUploaded: boolean = false
  validFileExtension: boolean = false
  validFileSize: boolean = false
  fileFailedToUpload:boolean = false
  uploadSub: Subscription | undefined


  uploadDocumentForm = new FormGroup({
    related_name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    category: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    description: new FormControl<string>('', {nonNullable: false }),
    file: new FormControl('',  { validators: Validators.required, nonNullable: false }),
  });

  get MaximumFileSizeInBytes():number {
    return MaximumFileSizeInMB*1024*1024
  }

  get MaximumFileSizeInMB():number {
    return MaximumFileSizeInMB
  }

  isValidFileType():boolean{

    let fileExtension = this.uploadedFile?.name.split('.').pop()
    if (fileExtension){
      return this.isInArray(this.allowed_file_extensions,fileExtension)
    }else{
      return false
    }
  }
  isInArray(array:String[], word:string):boolean {
    return array.indexOf(word.toLowerCase()) > -1;
}

  isValidFileSize():boolean{
    if (this.uploadedFile) {
      return (this.uploadedFile?.size <= this.MaximumFileSizeInBytes)
    }else{
      return false
    }

  }


  checkIfFileIsValid():void{
    if (this.isValidFileType()){
      this.validFileExtension = true
    }else{
      alert('Unsupported file format!')
      this.uploadDocumentForm.patchValue( {'file':''} );
      return;
    }
    if (this.isValidFileSize()){
      this.validFileSize = true
    }else{
      alert(`File size exceeds the maximum allowed size! (${MaximumFileSizeInMB} mb)`)
      this.uploadDocumentForm.patchValue( {'file':''} );
    }


  }
  


  onFileChange(event: Event):void {

    if (event.target) {
      const target = event.target as HTMLInputElement;
      if ((target.files as FileList).length > 0) {
        const file: File = (target.files as FileList)[0];
        this.uploadedFile = file
        this.checkIfFileIsValid()
        this.fileFailedToUpload = false
      }
    }
  }

  onUploadFail():void{ 
    this.fileFailedToUpload = true 
    this.fileBeingUploaded = false  
    this.uploadSub?.unsubscribe()
  }



  onSubmit(): void {

    if (this.uploadDocumentForm.invalid) {
      alert('Form is not valid')
      return
    }
    this.fileBeingUploaded = true
    this.fileFailedToUpload = false
    const formData: any = new FormData();
    formData.set("mission", this.data.missionId)
    formData.set("file", this.uploadedFile)
    formData.set("category", this.uploadDocumentForm.get("category")?.value)
    formData.set("description", this.uploadDocumentForm.get("description")?.value)
    formData.set("related_name", this.uploadDocumentForm.get("related_name")?.value)

    this.uploadSub?.unsubscribe()
    this.uploadSub = this.missionDocumentService.uploadDocumentOfMission(formData).subscribe(
      {
        next: () => this.dialogRef.close(),
        error: () => {
          this.onUploadFail()
        },
      }
    )
  }

  close(): void {
    this.uploadSub?.unsubscribe()
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.uploadSub?.unsubscribe()
  }

}
